
import { theme } from "@chakra-ui/core"
// import openColor from "open-color"
// import ibmColors from "ibm-design-colors/ibm-colors.json"
// import { red } from "@ant-design/colors"
// import { blue } from "material-ui-colors"

// const formattedIBMColors = Object.keys(ibmColors).reduce((acc, cur) => {
//   const hasSufficientKeys = Object.keys(ibmColors[cur]).length === 10
//   if (hasSufficientKeys) {
//     acc[cur] = Object.keys(ibmColors[cur]).reduce((acc2, cur2, i) => {
//       acc2[!i ? 50 : cur2 * 10] = ibmColors[cur][cur2]
//       return acc2
//     }, {})
//   } else {
//     acc[cur] = ["black","white"].includes(cur)
//       ? ibmColors[cur][Object.keys(ibmColors[cur])[0]]
//       : ibmColors[cur]
//   }
//   return acc
// }, {})

//
// For ant and oc
//
// function convertColorArray(c) {
//   return c.reduce((acc, cur, i) => {
//     if (!i) acc[50] = cur
//     else acc[i*100] = cur
//     return acc
//   }, {})
// }

//
// For ibm
//
// function convertIBMColors(c) {
//   return Object.keys(c).reduce((acc, cur, i) => {
//     acc[!i ? 50 : cur * 10] = c[cur]
//     return acc
//   }, {})
// }

// For OC
// convertColorArray(openColor.blue)

// For IBM
// convertIBMColors(ibmColors.blue)

// For ANT
// convertColorArray(red)

// For MUI
// Just use the color out of the box

const customColors = {
  // amber: {
  //   "50": "#FFF4E5",
  //   "100": "#FFEACC",
  //   "200": "#FFD699",
  //   "300": "#FFC166",
  //   "400": "#FFAD33",
  //   "500": "#FF9800",
  //   "600": "#E78B00",
  //   "700": "#CF7F00",
  //   "800": "#A66800",
  //   "900": "#7D5200",
  //   "1000": "#553B00",
  // },
  amber: {
    "1000": "#B84F13",
    "900": "#C66E21",
    "800": "#D48C30",
    "700": "#E2A741",
    "600": "#F1C053",
    "500": "#FFD567",
    "400": "#FEDB80",
    "300": "#FEE299",
    "200": "#FDE8B2",
    "100": "#FEEFCA",
    "50": "#FEF6E2",
  },

  coral: {
    "50": "#FFEEEE",
    "100": "#FFDEDF",
    "200": "#FFBCBE",
    "300": "#FF9B9E",
    "400": "#FF797D",
    "500": "#FF585D",
    "600": "#E5454C",
    "700": "#CB333B",
    "800": "#A32A31",
    "900": "#7B2127",
    "1000": "#53171D",
  },
  // blue: {
  //   "50": "#E5EFF4",
  //   "100": "#CCE0EA",
  //   "200": "#99C0D6",
  //   "300": "#66A1C1",
  //   "400": "#3381AD",
  //   "500": "#006298",
  //   "600": "#004E79",
  //   "700": "#003B5C",
  //   "800": "#00304C",
  //   "900": "#00263D",
  //   "1000": "#001B2E",
  // },
  // blue: {
  //   "1000": "#01212C",
  //   "900": "#01384A",
  //   "800": "#015067",
  //   "700": "#026984",
  //   "600": "#0282A2",
  //   "500": "#039DBF",
  //   "400": "#2AB0CD",
  //   "300": "#55C3D9",
  //   "200": "#7FD4E5",
  //   "100": "#A9E3EF",
  //   "50": "#D4F2F7",
  // },
  blue: {
    "1000": "#0D3142",
    "900": "#0E4259",
    "800": "#0D5570",
    "700": "#0B6B89",
    "600": "#0883A3",
    "500": "#039DBF",
    "400": "#28A7C5",
    "300": "#4CB1CC",
    "200": "#6FBED4",
    "100": "#92CBDC",
    "50": "#B3D9E6",
  },
  teal: {
    "50": "#E5F3F4",
    "100": "#CCE8EA",
    "200": "#99D1D5",
    "300": "#66BABF",
    "400": "#33A3AA",
    "500": "#008C95",
    "600": "#00737B",
    "700": "#005C62",
    "800": "#004B51",
    "900": "#003B41",
    "1000": "#002A31",
  },
  mint: {
    "50": "#F8FAF8",
    "100": "#F2F5F3",
    "200": "#E5EBE7",
    "300": "#D9E2DA",
    "400": "#CCD8CE",
    "500": "#BFCEC2",
    "600": "#A9BDAD",
    "700": "#94AD99",
    "800": "#859D8C",
    "900": "#687E73",
    "1000": "#4B605A",
  },
  quartz: {
    "50": "#FEF8F7",
    "100": "#FEF3F1",
    "200": "#FCE6E3",
    "300": "#FBDAD4",
    "400": "#F9CDC6",
    "500": "#F8C1B8",
    "600": "#F3AAA4",
    "700": "#F09491",
    "800": "#D88685",
    "900": "#A96C6D",
    "1000": "#7A5255",
  },
  petrol: {
    "50": "#ECF1F3",
    "100": "#D9E3E8",
    "200": "#B3C7D1",
    "300": "#8DACBB",
    "400": "#6790A4",
    "500": "#41748D",
    "600": "#36677C",
    "700": "#2B5B6C",
    "800": "#224A5A",
    "900": "#1A3A48",
    "1000": "#112A36",
  },
  plum: {
    "50": "#EDEBED",
    "100": "#DBD7DB",
    "200": "#B7AFB7",
    "300": "#938894",
    "400": "#6F6070",
    "500": "#4B384C",
    "600": "#3C2C3D",
    "700": "#2E222F",
    "800": "#241C27",
    "900": "#17121B",
    "1000": "#05060B",
  },
  seaweed: {
    "50": "#E8EBE9",
    "100": "#D3D7D5",
    "200": "#A7AFAA",
    "300": "#7A8780",
    "400": "#4E5F55",
    "500": "#22372B",
    "600": "#1C2F25",
    "700": "#18271F",
    "800": "#132019",
    "900": "#0C1512",
    "1000": "#020707",
  },
  gray: {
    "50": "#F2F3F4",
    "100": "#E6E8E9",
    "200": "#CDD2D5",
    "300": "#B4BABF",
    "400": "#96A0A6",
    "500": "#78848D",
    "600": "#5A6974",
    "700": "#3C4E5A",
    "800": "#1E3241",
    "900": "#051C2C",
  }
}

export default {
  ...theme.colors,
  textColor: customColors.gray[900],
  primary: customColors.blue[500],
  debugColor: "transparent",
  ...customColors,
  //
  // Included in theme.colors
  //
  // transparent: "transparent",
  // current: "currentColor",
  // black: "",
  // white: "",
  // whiteAlpha: {},
  // blackAlpha: {},
  // gray: {},
  // red: {},
  // orange: {},
  // yellow: {},
  // green: {},
  // teal: {},
  // blue: {},
  // cyan: {},
  // purple: {},
  // pink: {},
  // linkedin: {},
  // facebook: {},
  // messenger: {},
  // whatsapp: {},
  // twitter: {},
  // telegram: {},

  
}
