
import { theme } from "@chakra-ui/core"

import customIcons from "./icons"
import colors from "./colors"

export default {
  ...theme,
  colors,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
  fonts: {
    body: "'cg', Courier, system-ui, sans-serif",
    heading: "'vg', Impact, system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    "2xl": "2rem",
    "3xl": "2.75rem",
    "4xl": "3.5rem",
    "5xl": "4.25rem",
    "6xl": "5rem",
  },
  breakpoints: ["30.0625em", "48.0625em", "62em", "80.0625em"],
  lineHeights: {
    normal: "calc(1em + 0.5rem)",
    none: "1",
    shorter: "calc(1em + 0.125rem)",
    short: "calc(1em + 0.25rem)",
    base: "calc(1em + 0.5rem)",
    tall: "calc(1em + 0.75rem)",
    taller: "calc(1em + 1rem)",
    dense: "calc(1em + 0.25rem)",
    loose: "calc(1em + 0.75rem)",
  },
}
