
import { css } from "@emotion/core"

const globalStyles = css`
  html {
    font-family: "cg", Courier, system-ui, sans-serif;
    font-weight: 500;
    line-height: calc(1em + 0.5rem);
  }
  body {
    background-color: #FFF;
  }
  .gatsby-highlight-code-line {
    display: block;
  }
  .gatsby-highlight-code-line {
    background: rgba(255,255,255,0.1);
  }
  .game-preview-link {
    .cover {
      transition: transform 0.25s ease;
    }
    &:hover,
    &:focus {
      .cover {
        transform: scale(1.1);
      }
    }
  }
`

export default globalStyles
