
import React from "react"

export default {
  heart: {
    viewBox: "0 0 24 24",
    path: (
      <path
        stroke="currentcolor"
        fill="none"
        strokeWidth={2}
        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
      />
    )
  },
  share: {
    viewBox: "0 0 24 24",
    path: (
      <g stroke="currentcolor" fill="none" strokeWidth={2}>
        <circle cx="18" cy="5" r="3" />
        <circle cx="6" cy="12" r="3" />
        <circle cx="18" cy="19" r="3" />
        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
      </g>
    ),
  },
  twitter: {
    viewBox: "0 0 24 24",
    path: (
      <path
        // stroke="currentcolor"
        fill="currentcolor"
        // strokeWidth={2}
        d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z"
      />
    )
  },
  menu: {
    viewBox: "0 0 24 24",
    path: (
      <path
        stroke="currentcolor"
        strokeWidth={2}
        fill="none"
        d="M3,12L21,12M3,6L21,6M3,18,21,18"
      />
    ),
  },
  customStar: {
    viewBox: "0 0 28 28",
    path: (
      <path
        stroke="currentcolor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#FFD567"
        d="M13.551 3.242a.5.5 0 01.897 0l3.157 6.395 7.06 1.031a.5.5 0 01.276.854l-5.108 4.975 1.205 7.028a.5.5 0 01-.725.527L14 20.732l-6.314 3.32a.5.5 0 01-.725-.527l1.205-7.028-5.108-4.976a.5.5 0 01.277-.853l7.06-1.031 3.156-6.395z"
      />
    ),
  },
  achievementStar: {
    viewBox: "0 0 28 28",
    path: (
      <path
        stroke="currentcolor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#FFD567"
        d="M13.103 4.317a1 1 0 011.794 0l2.811 5.695 6.288.92a1 1 0 01.553 1.705L20 17.068l1.073 6.259a1 1 0 01-1.45 1.054L14 21.424l-5.622 2.957a1 1 0 01-1.451-1.054L8 17.068l-4.55-4.431a1 1 0 01.554-1.706l6.288-.919 2.811-5.695z"
      />
    ),
  },
  grayStar: {
    viewBox: "0 0 28 28",
    path: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentcolor"
        d="M13.103 4.317a1 1 0 011.794 0l2.811 5.695 6.288.92a1 1 0 01.553 1.705L20 17.068l1.073 6.259a1 1 0 01-1.45 1.054L14 21.424l-5.622 2.957a1 1 0 01-1.451-1.054L8 17.068l-4.55-4.431a1 1 0 01.554-1.706l6.288-.919 2.811-5.695z"
      />
    ),
  },
  trophyIcon: {
    viewBox: "0 0 28 28",
    path: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentcolor"
        clipRule="evenodd"
        d="M12.784 19.398c-3.448-.461-6.292-2.853-7.408-6.048A6.088 6.088 0 010 7.304v-4.26c0-.337.273-.61.609-.61h4.26V.61c0-.336.272-.609.609-.609H22.52c.337 0 .609.273.609.609v1.826h4.262c.337 0 .609.272.609.608v4.261a6.088 6.088 0 01-5.378 6.046c-1.116 3.195-3.959 5.586-7.405 6.047v3.733a2.435 2.435 0 002.435 2.435h.609c1.008 0 1.826.818 1.826 1.826V28H7.914v-.609c0-1.008.818-1.826 1.826-1.826h.609a2.435 2.435 0 002.435-2.435v-3.732zm10.285-7.992a4.269 4.269 0 003.106-4.102V4.261H23.13v6.087c0 .358-.02.71-.061 1.058zm-18.2-7.145H1.826v3.043a4.269 4.269 0 003.104 4.101c-.04-.347-.06-.7-.06-1.057V4.26z"
      />
    ),
  },
  soundOn: {
    viewBox: "0 0 24 24",
    path: (
      <g
        stroke="currentcolor"
        fill="none"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
        <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
      </g>
    ),
  },
  soundOff: {
    viewBox: "0 0 24 24",
    path: (
      <path
        // stroke="currentcolor"
        fill="currentcolor"
        // strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.245 8.344A.996.996 0 005 9v6a1 1 0 001 1h3.65l4.725 3.78A1 1 0 0016 19v-1.632l-2-1.678v1.23l-3.375-2.7A1 1 0 0010 14H7v-4h.219L5.245 8.344zM14 10.469V7.08l-2.067 1.653-1.563-1.311 4.005-3.204A1 1 0 0116 5v7.147l-2-1.678zM2.758 5.066a1 1 0 011.285-1.532l16.853 14.141a1 1 0 11-1.285 1.532L2.758 5.067z"
      />
    ),
  },
  check: {
    viewBox: "0 0 24 24",
    path: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentcolor"
        clipRule="evenodd"
        d="M2 12l2-2 4 3s4.375-5.458 13-8l1 2s-9.438 4.375-14 13l-6-8z"
      />
    ),
  },
  playAgain: {
    viewBox: "0 0 24 24",
    path: (
      <path
        stroke="currentcolor"
        fill="none"
        strokeWidth={2}
        d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38"
      />
    )
  },
}
